<template>
  <transition
      name="fade-and-fly"
  >
  <div class="login" v-if="!loading && store.state.module.popup == 'login'">
    <div class="login-panel">
    <div class="panel with-header with-button" v-if="!loading">
      <div class="panel-top">
        <div class="panel-header">
          <span class="font-ang" v-if="tab==0">Login</span>
          <span class="font-ang" v-if="tab==1">Register</span>
          <span class="font-ang" v-if="tab==2">Reset Password</span>
        </div>
      </div>
      <div class="panel-middle">
        <div class="panel-content">
        <div class="tab-login" v-if="tab == 0">
          <form @submit.prevent>
            <input @input="anyInput" v-model="username" type="text" placeholder="Username..." class="panel-input"/>
            <input @input="anyInput" v-model="password" type="password" placeholder="Password..." class="panel-input"/>
            <input type="submit" name="CONTINUE" class="fake-submit" @click="submit"/>
            <span class="login-option-label font-ris">Forgot Password? Click <span class="option-label-button font-ris" @click="switchPanel(2)">HERE</span></span>
            <span class="login-option-label font-ris">Dont have an account? <span class="option-label-button font-ris" @click="switchPanel(1)">REGISTER</span></span>
          </form>
        </div>
        <div class="tab-register" v-if="tab == 1">
          <form @submit.prevent>
            <span class="suggested-tooltip" v-if="suggested"><i>Suggested</i><span></span></span>
            <input @input="signupInput" v-model="usernameSignup" type="text" placeholder="Username..." class="panel-input"/>
            <span :class="suggesting ? 'suggest-new suggesting' : 'suggest-new'" @click="getSuggestedUsername">Suggest</span>
            <input @input="anyInput" v-model="password" type="password" placeholder="Password..." class="panel-input"/>
            <input @input="anyInput" v-model="passwordRepeat" type="password" placeholder="Password repeat..." class="panel-input"/>
              <p class="tc-notif">By creating an account you agree to <br/>
                <span @click="store.commit('goTo', '/terms_and_conditions')">Terms and Conditions</span> and
                <span @click="store.commit('goTo', '/privacy_policy')">Privacy Policy</span></p>
            <input type="submit" name="CONTINUE" class="fake-submit" @click="submit"/>
            <span class="login-option-label font-ris">Have an account? <span class="option-label-button font-ris" @click="switchPanel(0)">LOGIN</span></span>
          </form>
        </div>
          <div class="tab-register" v-if="tab == 2">
            <p class="forgot-descr">Enter wallet adress, which is<br/>connected to your user account.<br/>You will need to sign a message with this wallet.</p>
            <form @submit.prevent>
              <input @input="anyInput" v-model="forgotWallet" type="text" placeholder="Wallet address..." class="panel-input"/>
              <input @input="anyInput" v-model="password" type="password" placeholder="Password..." class="panel-input"/>
              <input @input="anyInput" v-model="passwordRepeat" type="password" placeholder="Password repeat..." class="panel-input"/>
              <input type="submit" name="CONTINUE" class="fake-submit" @click="getMessage"/>
              <span class="login-option-label font-ris">Have an account? <span class="option-label-button font-ris" @click="switchPanel(0)">LOGIN</span></span>
            </form>
          </div>
          <div class="tab-register" v-if="tab == 3">
            <p class="forgot-descr">New password successfully set!<br></p>
            <span class="login-option-label font-ris">Go to <span class="option-label-button font-ris" @click="switchPanel(0)">LOGIN</span></span>
          </div>
          <p class="error" v-if="error != ''">Error: {{error}}</p>
      </div>
      <div class="panel-footer">
        <div  class="font-ang panel-button" @click="submit"><span>CONTINUE</span></div>
      </div>
      </div>
      <div class="panel-bottom"></div>
    </div>
    </div>
  </div>
  </transition>
</template>

<script>
import Vue, {computed, watch} from "vue";
import {authenticateUser, getMessage, getSuggestedUsername, getUserProfile, registerUser, resetPassword} from "@/api";
import store from "@/store";
import {color} from "chart.js/helpers";
import ethereum from "@usedapp/core/src/model/chain/ethereum";
import {Web3} from "web3";
import {useStore} from "vuex";


export default {
  name: 'LoginPopup',
  props: {

  },
  data() {
    return {
      tab: 0,
      username: "",
      password: "",
      passwordRepeat: "",
      loading: true,
      store,
      error: '',
      forgotWallet: '',
      messageToSign: '',
      usernameSignup: '',
      suggested: false,
      suggesting: false
    }
  },
  setup() {
    console.log('popup setup')
    const myVariable = computed(() => store.state.popup);

    watch(myVariable, (newValue, oldValue) => {
      console.log('watch worked!!')
      this.loading = false;

      this.tab = 0;
      this.username = "";
      this.password = "";
      this.passwordRepeat = ""


      this.error = '';
      this.forgotWallet = '';
      this.messageToSign = '';
      this.suggested = true;
      this.usernameSignup = '';
    });

    return {
      myVariable
    };
  },
  methods: {
    async forgot() {
      if(this.password == null || this.password != this.passwordRepeat)
        return;

      
    },
    async getMessage() {
      console.log('get called???')
      if(this.password == null || this.password != this.passwordRepeat || this.forgotWallet == null)
        return;
      
      const resp = await getMessage(this.forgotWallet);
      if(resp != null && resp.status == 200) {
        this.messageToSign = resp.data;
      } else {
        this.error = 'Error generating message to sign'
        return;
      }

      let signed = '';
      try {
        let web3 = undefined;
        if (!web3) {
          try {
            //@ts-ignore
            await window.ethereum.enable();
            //@ts-ignore
            web3 = new Web3(window.ethereum);
          } catch (error) {
            this.store.commit(
                "setError",
                "Wallet was not allowed.",
            );
            if (error instanceof Error) {
              console.log('Name:', error.name);
              console.log('Message:', error.message);
              console.log('Stack:', error.stack);
            } else {
              console.log('Non-standard error:', error);
            }
            return;
          }
        }
        
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const from = accounts[0];
        // For historical reasons, you must submit the message to sign in hex-encoded UTF-8.
        // This uses a Node.js-style buffer shim in the browser.
        //const msg = `0x${Buffer.from(this.messageToSign, "utf8").toString("hex")}`;
        const msg = `0x${new TextEncoder().encode(this.messageToSign).reduce(
            (str, byte) => str + byte.toString(16).padStart(2, '0'),
            ''
        )}`;
        const sign = await window.ethereum.request({
          method: "personal_sign",
          params: [msg, from],
        });
        signed = sign;
      } catch (err) {
        this.error = 'Failed to sign a message';
        if (err instanceof Error) {
          console.log('Name:', err.name);
          console.log('Message:', err.message);
          console.log('Stack:', err.stack);
        } else {
          console.log('Non-standard error:', err);
        }
      }
      console.log('sign?', signed)
      const body = {
        wallet: this.forgotWallet,
        message: this.messageToSign,
        signedMessage: signed,
        newPassword: this.password
      };
      const response = await resetPassword(body, null);
      if(response != null && response.status == 200) {
        this.tab = 3;
      } else {
        this.error = 'Failed to set new password'
        return;
      }
    },
    async submit(){
      if(this.tab == 3) {
        this.tab = 0;
        return;
      }
      if(this.tab == 2) {
        this.getMessage();
        return;
      }
      if(this.tab == 0) {
        if(this.username == '' || this.password == '')
          return;
        
        this.loading = true;
        this.store.commit('setLoading', true);
        this.login();
      } else {
        if(this.usernameSignup == '' || this.password == '' || this.passwordRepeat == '')
          return;
        if(this.password != this.passwordRepeat)
          return;
        
        this.loading = true;
        const body = {
          email: "test",
          username : this.usernameSignup,
          password : this.password,
          confirmPassword : this.passwordRepeat
        };
        const response = await registerUser(body);
        if(response != null && response.status == 200) {
          this.username = this.usernameSignup;
          this.login();
        } else {
          this.store.commit('setLoading', false);
          this.loading = false;
          this.error = response?.response?.data;
          this.store.commit('setPopup', 'login');
        }
        
      }
       
      //this.$store.commit('setPopup', 'play')
    },
    anyInput() {
      this.error = '';
      console.log('any input')
    },
    signupInput() {
      this.suggested = false;
      this.anyInput();
    },
    async getSuggestedUsername() {
      if(this.suggesting)
        return;
      
      this.suggesting = true;
      const name = await getSuggestedUsername();
      this.usernameSignup = name.data;
      this.suggested = true;
      this.suggesting = false;
    },
    async login() {
      const body = {
        username : this.username,
        password : this.password,
      };
      const response = await authenticateUser(body);
      if(response != null && response.status == 200) {
        this.$store.commit('setToken', response.data.access_token);
        this.$store.commit('setRefreshToken', response.data.refresh_token);
        this.$store.commit('setUserName', this.username);
      } else {
        this.loading = false;
        this.store.commit('setLoading', false);
        this.error = response?.response?.data;
        this.store.commit('setPopup', 'login');
        //console.log(response?.response?.data)
        return;
      }
      const user =  await getUserProfile();
      if(user != null && user.status == 200) {
        this.store.commit('setUser', user.data);
        this.store.commit('setLoggedIn', true);
        if(user.data.walletsWithValidNFTs?.length > 0)
          this.store.commit('setUserWallet',user.data.walletsWithValidNFTs[0].walletAddress);
      } else {
        this.loading = false;
        this.store.commit('setLoading', false);
        return;
      }
      this.loading = false;
      this.store.commit('setLoading', false);
      this.store.commit('clearPopup');
      if(this.store.getters.getShopStep == '')
        this.store.commit('goTo', '/profile');
     
    },
    switchPanel(tab) {
      this.tab = tab;
      this.error = '';
    }
  },
  mounted() {
    this.getSuggestedUsername();
    setTimeout(() => {
      //this.loading = false;
    }, 100);
    this.loading = false;

    this.tab = 0;
    this.username = "";
    this.password = "";
    this.passwordRepeat = ""


    this.error = '';
    this.forgotWallet = '';
    this.messageToSign = '';
  },
  updated() {
    console.log('updated')
  }
}
</script>

<style lang="scss" scoped>
.tc-notif {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.tc-notif span {
  text-decoration: underline;
  color: #fff846;
  cursor: pointer;
}
.forgot-descr {
  color: #fff;
  text-align: center;
}
.error {
  color: #ff8585;
  text-align: center;
}
.login {
  position: absolute;
  top: 150px;
  left: calc(50% - 235px);
  /* right: 0px; */
  z-index: 150;
}
.login-panel {
  min-height: 420px;
  margin: 0px auto;
  width: auto;
  min-width: 400px;
  max-width: 500px;
  position: relative;
}
.tab-panel span {

}
.tab-panel .tab-selected {

}
.tab-content {

}
.tab-title {

}

.panel-input {
  width: 60%;
  margin: 0px auto 30px;
  display: block;
  height: 50px;
  border-radius: 15px;
  outline: navajowhite;
  border: navajowhite;
  box-shadow: 1px 1px 0px #666, 1px 1px 0px inset #000;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 0 20px;
}
::placeholder {
  color: #999;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #999;
}
.fake-submit {
  position: absolute;
  left: -9999px;
}

.login-option-label {
  width: 90%;
  display: block;
  position: relative;
  margin: 0px auto;
  text-align: center;
  color: #ccc;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding-bottom: 10px;
}
.login-option-label span {
color: #fff846;
font-size: 18px;
text-decoration: underline;
cursor: pointer;
}
.fade-and-fly-enter-active,
.fade-and-fly-leave-active {
transition: opacity 0.2s, transform 0.2s;
}

.fade-and-fly-enter,
.fade-and-fly-leave-to {
opacity: 0;
transform: translateY(-200px);
}

.fade-and-fly-leave {
opacity: 0;
transform: translateY(-200px);
}

.suggested-tooltip {
  background: #eee;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0 0 5px 30px;
  display: inline-block;
  position: relative;
}
.suggested-tooltip i {
  z-index: 50;
  position: relative;
}
.suggested-tooltip span {
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    background: #eee;
    transform: rotate(45deg);
    bottom: -5px;
    z-index: 30;
    right: 10px;
}
.suggest-new {
  background: #5a6383;
  padding: 2px 5px;
  border-radius: 5px;
  margin: -25px 55px 10px 0;
  display: inline-block;
  float: right;
  cursor: pointer;
  color: #eee;
}
.suggest-new.suggesting {
  background: #484f69;
}
</style>
